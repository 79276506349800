<!--
 * @Description:系统管理-app管理-轮播图管理 slideShowManagement
 * @Author:zhoucheng
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-25 16:06:11
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-form-item label-width="90px"
                        label="轮播图名称">
            <el-input v-model="searchForm.advertiseName"
                      placeholder="请输入"></el-input>
          </el-form-item>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>轮播图管理</span>
        <el-button class="tableTitleButton"
                   type="success"
                   @click="add">新 增</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  ref="tableRef"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="缩略图">
              <template slot-scope="scope">
                <a :href="$imgBaseUrl + scope.row.picUrl"
                   target="_blank">
                  <img slot="reference"
                       :src="$imgBaseUrl + scope.row.picUrl"
                       style="width:90px; height:60px"></a>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="是否启用"
                             prop="advType">
              <template slot-scope="scope">
                <!--1为启用、 2为停用 -->
                <el-switch v-model="scope.row.advStatus"
                           active-color="#13ce66"
                           inactive-color="#ff4949"
                           active-value="1"
                           inactive-value="2"
                           @change="changeSwitch(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope"
                        class-name='class-name'>
                <el-col :span="8">
                  <el-button @click="editRow(scope.row)"
                             type="text"
                             size="medium">编辑</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             @click="deleteRow(scope.row)"
                             size="medium">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 弹出框 -->
    <el-dialog :title="this.dialogTitle"
               :visible.sync="slideShowVisible"
               append-to-body
               @close="popClose">
      <el-form :model="form"
               ref="form"
               :rules="rules"
               :inline="true"
               size="small">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="轮播图标题"
                          prop="advertiseName"
                          label-width="100px">
              <el-input v-model="form.advertiseName"
                        placeholder="请输入"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <span class="span"
                style="">轮播图</span>
          <el-upload v-if="!form.picUrl"
                     class="avatar-uploader"
                     action=""
                     accept="image/*"
                     :http-request="upLoadPic"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload"
                     :limit="1">
            <el-button size="small"
                       type="primary">点击上传</el-button>
          </el-upload>
          <div v-else>
            <el-button size="small"
                       type="primary"
                       @click="deleteClick">删除</el-button>
            <el-image :src="$imgBaseUrl + form.picUrl"></el-image>
          </div>
        </el-row>
      </el-form>
      <el-row type="flex"
              justify="end">
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="confirm('form')"
                     style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
          <el-button @click="slideShowVisible = false"
                     type="info">取 消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import Compressor from 'compressorjs'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      dialogTitle: '', //弹出层名称
      slideShowVisible: false, //弹出层显示
      // 分页
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      // 表格线
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      // 表头
      tableColumnList: [
        {
          prop: 'advertiseName',
          label: '名称',
        },
        {
          prop: 'createrName',
          label: '创建人',
        },
      ],
      // 表格内容
      tableList: { list: [] },
      // 查询参数
      searchForm: {
        advertiseName: '',//轮播图名称
      },
      pageNum: 1,
      pageSize: 15,
      //新增修改表单
      form: {
        advertiseName: '',
        picUrl: '',
        position: '1',
        advType: '1',//轮播图类型：1PC/2APP
        createrName: '',
      },
      slideShowType: '', //弹出框状态 0为新增  1为编辑
      rules: {
        advertiseName: [
          { required: true, message: '请输入轮播图标题', trigger: 'blur' },
        ],
        picUrl: [
          { required: true, message: '请输入轮播图图片', trigger: 'blur' },
        ],
        advType: [
          { required: true, message: '请选择轮播图状态', trigger: 'change' }
        ],
      },
      // 下拉框
      options: [
        {
          value: '1',
          label: '停用'
        }, {
          value: '2',
          label: '启用'
        },
      ],
      Pager: null,//获取分页器
    }
  },
  //监听属性 类似于data概念
  computed: {
  },
  filters: {
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    //获取分页器组件
    this.Pager = this.$refs.tableRef.$children[1]
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    //初始化列表
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$slideShowManagement.queryAdvertiseList(this.searchForm).then((response) => {
        let list = response.resultEntity.list
        this.tableList.list = list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //查询
    onSubmit () {
      //设置分页器页面为1
      this.Pager.internalCurrentPage = 1
      // 设置序号为1
      this.listMap.pageNum = 1
      // 设置pageNum为1
      this.pageNum = 1
      this.queryTableList()
    },

    //重置表单
    resetForm () {
      this.searchForm = {}
      // 设置pageNum为1
      this.listMap.pageNum = 1
      // 设置序号为1
      this.pageNum = 1
      //设置分页器页面为1
      this.Pager.internalCurrentPage = 1
      this.queryTableList()
    },

    //新增
    new () {
      this.$slideShowManagement.addAdvertise(this.form).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({
            message: '新增成功',
            type: 'success'
          });
          this.queryTableList()
        } else {
          this.$message.error('新增失败');
        }
      })
    },

    //修改
    update () {
      this.$slideShowManagement.updateAdvertise(this.form).then((response) => {
        if (response.resultCode == 2000) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.queryTableList()
        } else {
          this.$message.error('修改失败');
        }
      })
    },

    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //新增
    add () {
      this.slideShowType = 0
      this.dialogTitle = '新增轮播图'
      this.slideShowVisible = true
      this.form = {
        advertiseName: '',
        link: '',
        picUrl: '',
        position: '1',
        advType: '1',
        remark: '测试',
      }
    },

    //编辑
    editRow (row) {
      this.slideShowType = 1
      this.dialogTitle = '编辑轮播图'
      this.slideShowVisible = true
      this.form = this.$deepClone(row)
    },

    //弹出框确认
    confirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.picUrl) {
            if (this.slideShowType == 1) {
              this.update()
            } else if (this.slideShowType == 0) {
              this.new()
            }
            this.slideShowVisible = false
          } else {
            this.$message({
              message: '请上传照片',
              type: 'warning'
            });
          }
        } else {
          return false;
        }
      });
    },

    //删除行记录
    deleteRow (row) {
      this.$confirm('确认删除该条记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = { advertiseId: row.advertiseId }
        this.$slideShowManagement.delAdvertise(info).then((response) => {
          if (response.resultCode == 2000) {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.queryTableList()
          } else {
            this.$message.error('删除失败');
          }
        })
      })
    },

    //开关   1是启用  2 是禁用
    changeSwitch (row) {
      let info = {
        advertiseId: row.advertiseId
      }
      if (row.advStatus === "2") {
        this.$slideShowManagement.disable(info).then(response => {
          if (response.resultCode == 2000) {
            this.$message({
              message: '停用成功',
              type: 'success'
            });
          } else {
            this.$message.error('停用失败');
          }
        })
      } else if (row.advStatus === "1") {
        this.$slideShowManagement.enable(info).then((response) => {
          if (response.resultCode == 2000) {
            this.$message({
              message: '启用成功',
              type: 'success'
            });
          } else {
            this.$message.error('启用失败');
          }
        })
      }
    },

    // 清空验证
    popClose () {
      this.$refs.form.resetFields();
    },

    // 上传图片
    handleAvatarSuccess (res, file) {
      this.form.link = URL.createObjectURL(file.raw);
    },

    // 提示
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG / PNG 格式!');
        return false
      }
      return true
    },

    //上传图片
    upLoadPic (file) {
      // 压缩图片
      const self = this
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          // console.log("res", result);
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$queryDict.upFile(formData).then(res => {
            console.log("上传成功", res);
            self.form.picUrl = res.resultEntity
          }).catch(err => {
            console.log('图片上失败', err)
          })
        },
        error (err) {
          console.log('压缩失败', err)
        }
      })
    },
    // 删除
    deleteClick () {
      this.form.picUrl = ''
    }
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    min-height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topFirstLine {
      display: flex;
      flex-direction: row;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
.el-button--success {
  background: #019fe4;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.span {
  display: inline-block;
  width: 100px;
  color: #70aee8;
  font-size: 14px;
  text-align: left;
  font-family: 'PingFang SC';
  font-weight: bold;
  padding: 0 12px 0 11.2px;
}

/deep/ .el-popover {
  background-color: transparent !important;
  border: 10px solid #ffffff;
}
</style>

